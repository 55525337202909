.graphedAnalyticsCard {
    margin-top: 20px;
    margin-bottom: 20px;
}

.graphedAnalyticsCard hr {
    border-top: 1px solid #DDDDDD;
    margin: 20px 0;
}


/* TODO responsive */
.graph {
    height: 450px;
    display: flex;
}

:global(.MuiTooltip-tooltip) {
    border: 1px solid #d9d9d9;
    background-color: #1B1B1B;
    color: white;
    border-radius: 16px;
    padding: 20px;
}