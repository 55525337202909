.statcard {
    text-align: left;
}

.cardContent {
    padding-top: 24px;
}

.headline {
    font-family: "Oswald", "Roboto", sans-serif;
}

.rate {
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 1.5rem;
    line-height: 1.4;
}

.amounts {
    margin-top: 10px;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #999;
}

.icon {
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--background);
    color: var(--clickrate)
}