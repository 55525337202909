.clickRateDisplay canvas {
    max-height: 175px;
    max-width: 175px;
}

.clickRateDisplay {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.graphContainer {
    position: relative;
}

.centerText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    pointer-events: none;
}

.percentageText {
}

.subText {
    font-size: 0.8rem;
}

.centerText span {
    display: block;
}

.text {
    margin-right: 15px;
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--background);
    color: var(--clickrate)
}

.rate {
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 1.5rem;
    line-height: 1.4;
}

.amounts {
    margin-top: 10px;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #999;
}