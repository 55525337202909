.title {
    font-family: "Oswald", "Roboto", sans-serif;
    display: flex;
    align-items: center;
}

.statCardPanel {
    margin-bottom: 20px;
    margin-top: 20px;
}

