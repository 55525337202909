.appbar {
    width: 300px;
}

.main {
    margin-left: 300px;
    padding: 40px 0;
}

/* TODO Responsiveness */
.content {
    padding-left: 40px;
    padding-right: 40px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1400px;
    box-sizing: border-box;
}