.templateCard {
    min-height: 483px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.dataTable {
    text-align: left;
}

.dataTable th {
    text-transform: uppercase;
    width: 33.33%;
    font-weight: normal;
}

.dataTable td {
}

.dataTable th:first-child {
    padding-left: 0;
}

.dataTable td:first-child {
    padding-left: 0;
}

.dataTable td .ratio {
    color: #999;
}

.dataTable :global(.MuiLinearProgress-root) {
    width: 100%;
    height: 15px;
    border-radius: 500px;
    color: #00acc7;
}

.dataTable :global(.MuiLinearProgress-root)::before {
    background-color: rgba(153, 153, 153, 0.51);
}

.dataTable :global(.MuiLinearProgress-dashed)::before {
    background-color: rgba(153, 153, 153, 0.51);
}

.dataTable tr:last-child td {
    border: none;
}

.dataTable :global(.MuiLinearProgress-dashed) {
    background-image: none;
    background-color: #EFEFEF;
    animation: none;
    -webkit-animation: none;
}

.legend {
    padding: 0;
    list-style: none;
    font-size: 0.8rem;
}

.legend li {
    margin-right: 10px;
}

.legend span {
    border: 1px solid #ccc;
    float: left;
    width: 12px;
    height: 12px;
    top: 3px;
    position: relative;
}

.legendCriticalInteraction {
    background-color: #D32F2F;
    margin-right: 5px;
}

.legendInteraction {
    background-color: #EEAFAF;
    margin-right: 5px;
}

.button {
    margin: 20px;
}

.title {
    display: flex;
    align-items: center;
}
