.sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 280px;
    padding: 40px 30px;
    font-family: "Oswald";
    height: 100%;
}

.sidebar h3 {
    font-size: 1.5rem;
    line-height: 1.4;
    font-weight: normal;
    margin: 20px 0px;
}

.navItem.selected, .navItem:hover {
    background-color: rgb(179, 190, 224, 0.5);
    /*background-color: #D1D5DB;*/
    border-radius: 7px;
    color: rgb(0, 123, 255);
}

.sidebar img {
    max-width: 220px;
}

.liIcon {
}

.liText {
    padding-left: 15px;
    text-transform: none;
}

.navItem {
    color: #333;
    margin-bottom: 5px;
}

.sidebar :global(.MuiDivider-root) {
    margin: 10px;
}


.sidebar :global(.MuiButtonBase-root) {

}


.navigation {

}

.account {

}

.sidebar :global(.MuiCardContent-root) {
    padding: 0;
}

.sidebar :global(.MuiCardHeader-root) {
    padding: 16px 16px;
    border-bottom: 1px solid lightgrey;
}

.userArea {
    margin-top: auto;
}

