.titleArea {
    color: var(--primary);
    font-size: 0.7475rem;
}

.titleArea h3 {
    margin-top: 0;
}

.textArea {
    font-size: 0.7375rem;
}

.container {

}