@font-face {
    font-family: 'Roboto';
    src: url('../public/assets/fonts/Roboto-Black.ttf') format('ttf');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Oswald';
    src: url('../public/assets/fonts/Oswald-VariableFont_wght.ttf') format('ttf');
    font-weight: 400;
    font-style: normal;
  }

[data-amplify-authenticator] [data-amplify-container] {
    width: inital!important;
}

div[data-amplify-authenticator][data-variation=modal] {
    background-color: #1C1C1C;
    background-size: 50%;
    background-position: 120% -200px;
    background-repeat: no-repeat;
    background-image: url(/public/assets/img/look2x-bildmarke-farbig-komplett.png);
}

div[data-amplify-router] {
    background-color: #fff !important;
    border-radius: 30px;
}
.amplify-label{
    margin: 0 auto!important;
    font-size: 1.5rem!important;
}

div[data-amplify-router-content] {
    background-color: #fff !important;
    padding: 0px 30px !important;
    width: max-content;
    margin: 0 auto;
    border-radius: 8px;
}


[data-amplify-authenticator] [data-amplify-form] {
    padding-top: 160px !important;
    padding-bottom: 20px!important;
    width: 100%!important;
    background-image: url(/public/assets/img/look2x-logo-farbig.svg);
    background-size: 350px 140px;
    background-position: center 15px;
    background-repeat: no-repeat;
}

[data-amplify-authenticator-resetpassword] label {
    display: none;
}

[data-amplify-authenticator-forcenewpassword] h3 {
    text-align: center;
    font-weight: normal;
}

[data-amplify-authenticator-forcenewpassword] label {
    display: none;
}


.amplify-button.amplify-button--primary {
    background: var(--primary) !important;
}

.amplify-button.amplify-button--primary:hover {
    background: #1C1C1C!important;
}

.amplify-button.amplify-field-group__control.amplify-field__show-password:hover {
    background: var(--primary) !important;
}

.amplify-button.amplify-field-group__control.amplify-button--link.amplify-button--small {
    font-weight: bold!important;
    color: var(--primary) !important;
}
.amplify-button.amplify-field-group__control.amplify-button--link.amplify-button--small:hover {
    background: #1C1C1C!important;
}
