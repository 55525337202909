html {
    font-size: 20px;
}

body {
  margin: 0;
    font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    background-color: var(--background);
}

h2 {
  font-weight: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*TODO responsive*/
.MuiCardContent-root {
  padding: 40px 40px;
}

:root {
    --primary: #a0aed9;
    --background: #F9F9F9;
    --clickrate: #66B3FF;
    --reportingrate: #005CB8;
    --interactionrate: #FFB666;
    --interactionrateLight: #FFE3C4;
    --negative: #E51837;
    --positve: green;
    --greytext: #808080;
}
