.trendStatCard {
    border-radius: 30px;
    border: 1px solid;
    text-align: left;
    padding: 20px 40px;
}

.titleArea {
    margin-bottom: 10px;
    font-size: 0.875rem;
    line-height: 1.5;
    color: black;
}

.rate {
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 1.5rem;
    line-height: 1.4;
}

.amounts {
    margin-top: 10px;
    font-size: 0.875rem;
    line-height: 1.5;
}

.rateDeltaContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.delta {
    display: flex;
    align-items: center;
}

.delta svg {
    margin-right: 5px;
}

.clickrate {
    color: var(--clickrate);
    border-color: var(--clickrate);
}

.reportingrate {
    color: var(--reportingrate);
    border-color: var(--reportingrate);
}

.negative {
    color: red;
}

.positive {
    color: green;
}