.dataTable td:not(:first-child) {
    width: 33%;
}

.dataTable th:first-child {
    padding-left: 0;
}

.dataTable td:first-child {
    padding-left: 0;
}

.dataTable :global(.MuiLinearProgress-root) {
    width: 100%;
    height: 15px;
    border-radius: 500px;
    color: #00acc7;
}

.dataTable :global(.MuiLinearProgress-root)::before {
    background-color: rgba(153, 153, 153, 0.51);
}

.dataTable tr:last-child td {
    border: none;
}