.errorimg {
    margin: 80px;
}

.errorimg img {
    height: 120px;
}

.errortext {
    margin-bottom: 20px;
}

.errorPage {
    display: flex;
    flex-direction: column;
}

.logo img {
    height: 100px;
    margin: 15px;
}

.logo {
    align-self: flex-start;
}

.button {
    text-align: center;
    width: 300px;
    align-self: center;
}
