.navigationHeader h1 {
    font-size: 2.325rem;
    line-height: 1.2;
    font-weight: normal;
    text-align: left;
    color: #333;
    font-family: "Oswald", "Roboto", sans-serif;
}

.navigationHeader li:not(li:last-child) a {
    color: #999;
    text-decoration: none;
}

.navigationHeader li {
    font-size: 0.875rem;
}

.navigationHeader li:last-child {
    color: #333333;
}

.navigationHeader :global(.MuiBreadcrumbs-separator) {
    margin: 0 20px;
}
