.baselineStatComparison {
    margin: 40px;
}

.baselineNumbers {
}

.followUpNumbers {
}

.titleArea {
    margin-bottom: 10px;
    font-size: 0.875rem;
    line-height: 1.5;
    color: black;
}

.rate {
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 1.5rem;
    line-height: 1.4;
}

.amounts {
    margin-top: 10px;
    font-size: 0.875rem;
    line-height: 1.5;
}

.trendArea {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.arrow img {
    height: 15px;
    width: 100%;
}

.delta {
    height: 60px;
    width: 60px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary);
    color: white;
}

.icon {
}