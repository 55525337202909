.receivertable {
    width: 925px;
    margin: 0 auto;
}
.campaigntable {
    width: 1150px;
    margin: 0 auto;
}

.templateTableLarge {
    width: 1200px;
    margin: 0 auto;
}

.templateTableSmall {
    margin: 0 auto;
}
.dispatchtable {
    width: 1150px;
    margin: 0 auto;
}
.tableButtons {
    width: 50%;
    margin: 0 auto;
}


.tableButtons button{
    margin: 1rem;
}