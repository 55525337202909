.headercard {
    background-image: url(../../../../public/assets/img/look2x-bildmarke-farbig.png);
    background-size: contain;
    margin-top: 40px;
    margin-bottom: 40px;
    background-color: #1b1b1b;
    background-position: 110% 0%;
    background-repeat: no-repeat;
    color: white;
}

.headercard :global(.MuiLinearProgress-root) {
    border-radius: 150px;
    margin-left: 20px;
}

/* TODO Responsiveness */
.textarea {
    font-size: 2rem;
    text-align: left;
}

.textarea > p {
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.7);
    margin: 20px;
}

.textarea .highlight {
    color: white;
}

.headercard h2 {
    color: white;
    font-family: "Oswald", "Roboto", sans-serif;
    margin-left: 20px;
    margin-top: 0px;
    font-weight: normal;
    font-size: 2.3rem;
}