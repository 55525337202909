.card {
    width: 50%;
    margin: 0 auto;
    border: 1px solid #ddd;
    margin-top: 20px;
    padding: 16px;
    /* Add padding for content spacing */
    background-color: #fff;
    /* Optional: Set background color */
    display: flex;
    /* Arrange items in a row */
    flex-direction: column;
    /* Stack items vertically */
}

.card .readOnly {
    opacity: 0.7;
    /* Adjust the opacity as needed */
    pointer-events: none;
    /* Disable pointer events */
}

.card button {
    margin-top: 20px;
}

.formControlSideBySide {
    width: 50%
}