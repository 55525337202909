.form {
    width: 70%;
    margin: 0 auto;
}

.form button {
    margin: 1rem;
}

.form .delete {
    background: red;
}

.displayNone {
    display: none!important;
}

.formControlSideBySide {
    width: 50%
}