.userPanel {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    text-align: left;
    border: 1px solid lightgrey;
    padding: 16px;
    border-radius: 16px;
}

.userAvatar {
    width: 50px;
    height: 50px;
    background-color: var(--primary);
    border-radius: 50%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.userRoleText {
    color: #999;
    font-size: 0.875rem;
}