.container {
    width: 30%;
    margin: 0 auto;
}

.container button {
    margin: 1rem;
}

.container input {
    margin: 1rem;
}

.container .alert {
    margin: 0 auto;
}