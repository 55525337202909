.form button {
}

.form .delete {
    background: red;
}

.displayNone {
    display: none !important;
}

.formControlSideBySide {
    width: 50%
}

.doubleInputArea {
    display: flex;
    justify-content: space-between;
}

.userEditor :global(.MuiTextField-root) {
    margin: 20px;
}

.userEditor hr {
    margin-top: 30px;
    margin-bottom: 30px;
}

.subtitle {
    margin-bottom: 15px;
    text-align: left;
    color: var(--greytext)
}

.saveButton {
    margin-top: 100px;
}

