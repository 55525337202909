.progressbar {
    display: flex;
    align-items: center;
    width: 100%;
}

.text {
    font-size: 0.8rem
}

.progressbar :global(.MuiLinearProgress-root) {
    width: 100%;
    margin-right: 10px;
    background-color: #EFEFEF;
}

.progressbar :global(.MuiLinearProgress-dashed) {
    background-image: none;
    background-color: #EFEFEF;
    animation: none;
    -webkit-animation: none;
}