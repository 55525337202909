.campaignOverviewCard {
    margin-top: 40px;
    text-align: left;
}

.campaignOverviewCard h3 {
    margin-top: 0px;
    margin-bottom: 20px;
    color: #333;
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 1.4;
    font-family: "Oswald", "Roboto", sans-serif;
}

.campaignOverviewCard:hover h3 {
    color: var(--primary);
}

.progressbar {
    margin-top: 20px;
    max-width: 550px;
}

.text {
    color: #999;
    font-size: 0.875rem;
}

.graphContainer {
    height: 175px;
}

.clickrateArea {
    display: flex;
    align-items: center;
}

.clickrateArea :global(.rate-display) {
    margin-right: 15px;
}