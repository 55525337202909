.dataTable td:last-child {
    width: 40%;
}

.dataTable td:first-child {
    padding-left: 0;
}

.dataTable :global(.MuiLinearProgress-root) {
    width: 100%;
    height: 15px;
    border-radius: 500px;
}

.dataTable tr:last-child td {
    border: none;
}

.dataTable th {
    border: none
}

.collapsed.dataTable td {
    border: none;
    padding-top: 20px;
    padding-bottom: 20px;
}

.legend {
    padding: 0;
    list-style: none;
    font-size: 0.8rem;
}

.legend li {
    margin-right: 10px;
}

.legend span {
    border: 1px solid #ccc;
    float: left;
    width: 12px;
    height: 12px;
    top: 3px;
    position: relative;
}

.legendCriticalInteraction {
    background-color: var(--interactionrate);
    margin-right: 5px;
}

.legendInteraction {
    background-color: var(--interactionrateLight);
    margin-right: 5px;
}