.container {
  font-size: 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  line-height: initial;
}

.container a {
  pointer-events: none;
}

.outroSectionButton a {
  pointer-events: auto;
}

button {
  cursor: pointer;
}

section {
  width: 90%;
  margin: 0 auto;
}

.header {
  flex: 0 0 auto;
  display: flex;
  padding: 1.2rem;
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.container {
  font-family: "Roboto", Arial, sans-serif;
}

.ownLogo {
  height: 10vh;
}

.customerLogo {
  height: 10vh;
}

.headerLogoCustomer {
  margin-left: auto;
}

.introSection {
  flex: 1;
  align-items: center;
}

.introHeadline {
  text-align: center;
  font-size: 4.2rem;
  font-family: "Oswald", "Roboto", Arial, Helvetica, sans-serif;
  font-weight: bold;
  padding: 0 0 1.2rem 0;
}

.logo2xOnly {
  height: 4.2rem;
}

.introContent {
  flex-direction: column;
  align-items: center;
  padding: 1.2rem;
  color: #ffffff;
  background: #a0aed9;
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  border-radius: 10px;
}

.introContentText {
  text-align: center;
}

.introContentEnumeration {
  display: flex;
  flex-direction: column;
  padding: 0.6rem 0;
}

.introContentEnumerationItem {
  display: flex;
  align-items: center;
  color: #000000;
  margin: 0.6rem 0;
  padding: 0.6rem;
  background: #ffffff;
  border-radius: 30px;
}

.introContentOpenNow {
  text-align: center;
}

.introContentMailButton {
  text-align: center;
  padding: 1.2rem 0 2.4rem 0;
  margin: 2.4rem;
}

:global(.react-joyride__tooltip) div {
  text-align: left;
}

:global(.react-joyride__tooltip) h1 {
  text-align: left;
  padding-left: 10px;
  font-family: "Oswald", "Roboto", Arial, Helvetica, sans-serif;
  font-weight: 500;
}

.checkmarkIcon {
  padding-right: 1.2rem;
}

.guideSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: fit-content;
  opacity: 0%;
  transition: opacity 1s ease-in-out;
  /* Animation for opacity change */
}

.mailHeaderBar {
  background: #eef3f9;
  display: flex;
  justify-content: right;
}

.mailHeaderBarIcon {
  padding: 0.6rem;
}

.mailHeaderInfo {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.mailHeaderItem {
  padding: 0.6rem;
  border: 1px solid #d3d3d3;
  margin-top: -1px;
}

.mailBody {
  border: 1px solid #d3d3d3;
  margin-top: -1px;
  padding: 1.8rem;
}

.guideButton {
  display: flex;
  justify-content: center;
  padding: 2.4rem;
}

.startTourButton {
  background: #a0aed9;
  color: white;
  border: none;
  padding: 1.2rem;
  border-radius: 5px;
  font-size: 1.5rem;
}

.additionalInfoSection {
  align-items: center;
  text-align: center;
}

.didYouKnowSectionContent {
  display: grid;
  margin: 0 auto;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.2rem;
}

.didYouKnowSectionContentItem {
  background: #a0aed9;
  color: white;
  padding: 1.2rem;
  border-radius: 5px;
}

.outroSectionLogo {
  margin: 3rem;
}

.outroSection div {
  padding: 1.2rem;
}

.homepageButton {
  background: #a0aed9;
  color: white;
  border: none;
  padding: 1.2rem;
  border-radius: 5px;
  font-size: 1.5rem;
  margin: 2.4rem;
}

.didYouKnowSectionHeadline {
  font-family: "Oswald", "Roboto", Arial, Helvetica, sans-serif;
  text-align: center;
  font-size: 3rem;
  font-weight: bold;
  padding: 0 0 2.4rem 0;
}

.react-joyride__spotlight {
  background-color: #fff !important;
  opacity: 0.5 !important;
}

.footer {
  text-align: center;
}

@media (max-width: 1600px) {
  .container {
    font-size: 20px;
    /* Adjust font size for smaller screens */
  }
}

@media (max-width: 1200px) {

  .container {
    font-size: 16px;
    /* Adjust font size for smaller screens */
  }

  section {
    width: 90%;
    /* Make sections full width on smaller screens */
    margin: 0 auto;
  }

  .ownLogo {
    height: 5vh;
  }

  .customerLogo {
    height: 5vh;
  }

  .introHeadline {
    font-size: 3rem;
  }

  .logo2xOnly {
    height: 3rem;
  }

  .didYouKnowSectionContent {
    grid-template-columns: 1fr;
    /* Switch to 1 column */
    grid-template-rows: repeat(3, auto);
    /* Create 3 rows */
  }
}

@media (max-height: 800px) {

  .container {
    font-size: 14px;
    /* Adjust font size for smaller screens */
  }
}