.dataTable td:last-child {
    width: 40%;
}

.dataTable td:first-child {
    padding-left: 0;
}

.dataTable :global(.MuiLinearProgress-root) {
    width: 100%;
    height: 15px;
    border-radius: 500px;
}

.dataTable tr:last-child td {
    border: none;
}

.dataTable th {
    border: none
}

.collapsed.dataTable td {
    border: none;
    padding-top: 20px;
    padding-bottom: 20px;
}

.extended.dataTable td {

}